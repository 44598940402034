import React, {useEffect} from "react";
import { useRef, useState } from "react";
import "./Clock.scss";

const Clock = () => {
  const hourHandRef = useRef(null);
  const minuteHandRef = useRef(null);
  const secondHandRef = useRef(null);

  const [backgroundColor, setBackgroundColor] = useState("rgb(234, 239, 244)");

  useEffect(() => {
    const hourHand = hourHandRef.current;
    const minuteHand = minuteHandRef.current;
    const secondHand = secondHandRef.current;
    
    const updateClock = () => {
      const date = new Date();
      const seconds = date.getSeconds();
      const minutes = date.getMinutes();
      const hours = date.getHours();

      const hourRotation = (hours % 12) * 30 + minutes / 2;
      const minuteRotation = minutes * 6;
      const secondRotation = seconds * 6;

      hourHand.style.transform = `rotate(${hourRotation}deg)`;
      minuteHand.style.transform = `rotate(${minuteRotation}deg)`;
      secondHand.style.transform = `rotate(${secondRotation}deg)`;

      setBackgroundColor(`rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255})`);


      requestAnimationFrame(updateClock);
    };

    requestAnimationFrame(updateClock);

    const intervalId = setInterval(updateClock, 1000);

    return () => {
      clearInterval(intervalId);
    };

  }, []);


  return (
    <div className="clock" style={{backgroundColor, transition: "background-color 1s ease"}}>
      <div class="outer">
        <div class="clock">
          <div class="numbers">
            <div class="num num3">
              <div id="num3">3</div>
            </div>
            <div class="num num6">
              <div id="num6">6</div>
            </div>
            <div class="num num9">
              <div id="num9">9</div>
            </div>
            <div class="num num12">
              <div id="num12">12</div>
            </div>
          </div>
          <div class="center"></div>
          <div ref={hourHandRef} class="hour hand"></div>
          <div ref={minuteHandRef} class="min hand"></div>
          <div ref={secondHandRef} class="sec hand"></div>
        </div>
      </div>
    </div>
  );
};

export default Clock;
