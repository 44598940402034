import { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import "./Home.scss";

const Home = () => {
  const [hide, setHide] = useState("");
  const handleHide = () => {
    setHide("hide");
  };
  const handleShow = () => {
    setHide("");
  };
    
  return (
    <div className={`home ${hide}`}>
      <div className="sidebar">
        <header>
          <h1>CSS Playground</h1>
        </header>
        <ul>
          <li>
            <Link to="/clock">Clock</Link>
          </li>
          <li>
            <Link to="/circles">Circles</Link>
          </li>
        </ul>
        <button onClick={handleHide}>Hide Menu</button>
      </div>
      <button className="showBtn" onClick={handleShow}>Show Menu</button>
      <div className="outlet">
              <Outlet />
      </div>
    </div>
  );
};

export default Home;
