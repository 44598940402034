import "./App.scss";
import Circles from "./pages/Circles";
import Clock from "./pages/Clock";
import Home from "./pages/Home";
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import ErrorPage from "./components/ErrorPage";

function App() {
  return (
    <BrowserRouter >
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="clock" element={<Clock />} />
          <Route path="circles" element={<Circles />} />
        </Route>
      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
