import React from "react";
import { useState, useEffect } from "react";
import "./Circles.scss";

const Circles = () => {
  const circles = [1, 2, 3, 4, 5, 6];

  const [bgColor, setBgColor] = useState('#000'); // Initial background color

  useEffect(() => {
    const colorInterval = setInterval(changeColor, 5000); // Change color every 5 seconds
    return () => clearInterval(colorInterval);
  }, []);

  const changeColor = () => {
    // Generate a random dark and colorful background color using HSL
    const hue = Math.floor(Math.random() * 360); // Random hue
    const saturation = '50%'; // You can adjust the saturation as needed
    const lightness = '30%'; // Adjust lightness for darkness

    const randomColor = `hsl(${hue}, ${saturation}, ${lightness})`;
    setBgColor(randomColor);
  };

  return (
    <div className="circles" style={{ backgroundColor: bgColor }}>
      {circles.map((circle, index) => (
        <div key={index} className="circles-container">
          <div className="innerCircles">
            {circles.map((circle, index) => (
              <div key={index} className="innerCircle circle"></div>
            ))}
          </div>

          <div className="middleCircles">
            {circles.map((circle, index) => (
              <div key={index} className="middleCircle circle"></div>
            ))}
          </div>

          <div className="outerCircles">
            {circles.map((circle, index) => (
              <div key={index} className="outerCircle circle"></div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Circles;
